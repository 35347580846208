<script>

import SimpleInfoTable from "../../../components/SimpleInfoTable/SimpleInfoTable.vue";
import {httpRequest} from "../../../api";
import SapTool from "@/components/DataTables/Tools/SapTool/index.vue";
import ImpressionsTool from "@/components/DataTables/Tools/ImpressionsTool/index.vue";
import DifficultyTool from "@/components/DataTables/Tools/DifficultyTool/index.vue";
import AppRankTool from "@/components/DataTables/Tools/AppRankTool/index.vue";
import RankChartTool from "@/components/DataTables/Tools/RankChartTool/index.vue";
import ResultsTool from "@/components/DataTables/Tools/ResultsTool/index.vue";
import CompetitorsInTopTool from "@/components/DataTables/Tools/CompetitorsInTopTool/index.vue";
import TranslateOneTool from "../../../components/DataTables/Tools/TranslateOneTool/index.vue";
import KeywordTool from "@/components/DataTables/Tools/KeywordTool/index.vue";
import InAsoTool from "@/components/DataTables/Tools/InAsoTool/index.vue";
import KeywordToolNew from "../../../components/DataTables/Tools/KeywordToolNew.vue";
import SvgIcon from "../../../components/UI/SvgIcon/index.vue";
import TranslateTool from "@/components/DataTables/Tools/TranslateTool/index.vue";
import {
  arraySum,
  capitalizeFirstLetter,
  formatDate,
  getDefaultMinDate,
  getNDaysAgoDate,
  openGlobalModal
} from "../../../utils/utils";
import CustomSelectNew from "../../../components/Forms/CustomSelectNew/index.vue";
import ModalComponent from "../../../components/Modals/ModalComponent.vue";
import KeywordsGroups from "../../../api/modules/keywords-groups";
import ProgressIndicator from "../../../components/UI/ProgressIndicator/index.vue";
import MetadataKeywordsTable from "../../CompetitorsFinderPage/Competitors/Metadata/MetadataKeywordsTable.vue";
import {nextTick} from "vue";
import LiveSearchGlobalModalAdapter from "../../../components/Modals/LiveSearchModal/LiveSearchGlobalModalAdapter.vue";
import SapChartGlobalModalAdapter from "../../../components/Modals/SapChartModal/SapChartGlobalModalAdapter.vue";
import AutosuggestsGlobalModalAdapter
  from "../../../components/Modals/AutosuggestsModal/AutosuggestsGlobalModalAdapter.vue";
import keywordsTracking from "../../../api/modules/keywords-tracking";
import Tabs from "../../../components/DataTables/GroupsAssets/Tabs.vue";
import {
  compInTop10Filter,
  difficultyFilter,
  estDailyImprFilter,
  languageFilter,
  nonCompInTop10Filter,
  popularityFilter,
  rankFilter,
  rankTrendFilter,
  wordsCountFilter
} from "../Common/filtersConfigs";
import {groupsConfig} from "../Common/groupsConfigs";
import {allTranslated, checkedItems, isShowTableBlur} from "../Common/otherCommonComputed";
import UnlockFullTableBlur from "../../../components/UI/UnlockFullTableBlur.vue";
import {copyKeywords, translateAll} from "../Common/methods";
import {mapGetters} from "vuex";


export default {
  name: "RankingKeywordsTable",
  components: {
    UnlockFullTableBlur,
    Tabs,
    MetadataKeywordsTable,
    ProgressIndicator,
    ModalComponent,
    CustomSelectNew,
    TranslateTool,
    SvgIcon,
    KeywordToolNew,
    KeywordTool,
    InAsoTool,
    TranslateOneTool,
    CompetitorsInTopTool,
    ResultsTool,
    RankChartTool,
    AppRankTool,
    ImpressionsTool,
    SapTool,
    SimpleInfoTable,
    DifficultyTool
  },
  props: {
    workingApp: {
      type: Object,
      required: true,
    },
    currentCountry: {
      type: Object,
      required: true,
    },
    dateFilter: {
      type: Object,
      required: false,
      default: () => {
        return {
          start: getNDaysAgoDate(2),
          end: getNDaysAgoDate(1),
        }
      },
    },
  },
  emits: ['date-filter-changed', 'tracking-keywords-changed'],
  data() {
    return {
      tableMaxHeightFix: 631,

      loading: true,

      keywordsGroups: new KeywordsGroups(),

      tabSelectOpen: false,

      items: [],
      total: 0,

      groupsSelectReloadKey: 0,
      groupsInfo: {},
      selectedGroup: null,
      selectedTab: null,

      dates: {
        start: getNDaysAgoDate(2),
        end: getNDaysAgoDate(1),
      },

      lastOpenedItem: null,
      searchInputValue: '',
    }
  },
  watch: {
    selectedTab() {
      nextTick(() => {
        this.$refs.table.resetPagination();
      });
    },
  },
  computed: {
    ...mapGetters([
      'currentApp',
      'userSubscription',
    ]),
    downloadUrl() {
      if (!this.loading) {
        const table = this.$refs.table;
        let urlQuery = table.getDataProvider().getSearchFilterData().paramsUrl();
        urlQuery += '&app_id=' + this.currentApp.id
            + '&country_code=' + this.currentCountry.code
            + '&date_from=' + formatDate(this.dates.start, 'digits-dash')
            + '&date_to=' + formatDate(this.dates.end, 'digits-dash');
        return process.env.VUE_APP_URL + this.$ApiUrls.exportsUrls.EXPORTS_RANKING_KEYWORDS + '?p=1' + urlQuery;
      }

      return null
    },
    columns() {
      return {
        'keywords': {
          header: 'Keywords',
          style: {width: '100%', minWidth: '260px', paddingLeft: 0, left: '65px'},
          headerStyle: {paddingLeft: 0, left: '65px'},
          orientation: 'left',
          sortBy: this.checkedItems.length ? null : (i) => i.keyword.term,
          sortExportParam: 'keyword',
        },
        'popularity': {
          header: 'Popularity',
          style: {paddingRight: '0px'},
          headerStyle: {minWidth: '130px', paddingRight: '0px'},
          orientation: 'right',
          sortBy: 'sap',
          sortExportParam: 'sap',
        },
        'impression': {
          header: 'Daily Impr',
          style: {},
          headerStyle: {minWidth: '125px'},
          orientation: 'right',
          sortBy: 'daily_impression',
          sortExportParam: 'daily_impression',
        },
        'difficulty': {
          header: 'Difficulty',
          style: {},
          headerStyle: {minWidth: '120px'},
          orientation: 'right',
          sortBy: 'difficulty',
          sortExportParam: 'difficulty',
        },
        'first_rank': {
          header: 'App Rank',
          style: {paddingRight: '0px'},
          headerStyle: {minWidth: '110px', paddingRight: '0px'},
          orientation: 'right',
          sortBy: (item) => {
            if (item.first_rank_info.rank === '-' || item.first_rank_info.rank === 'N/A') {
              return -8888;
            } else {
              return parseInt(item.first_rank_info.rank) * -1;
            }
          },
          sortExportParam: 'first_rank',
        },
        'last_rank': {
          header: 'App Rank',
          style: {paddingRight: '0px'},
          headerStyle: {minWidth: '110px', paddingRight: '0px'},
          orientation: 'right',
          sortBy: (item) => {
            if (item.last_rank_info.difference === 'out') {
              return -9999;
            } else if (item.last_rank_info.rank === '-' || item.last_rank_info.rank === 'N/A') {
              return -8888;
            } else {
              return parseInt(item.last_rank_info.rank) * -1;
            }
          },
          sortExportParam: 'latest_rank',
        },
        'applications_count': {
          header: 'Results',
          style: {},
          headerStyle: {minWidth: '100px'},
          orientation: 'right',
          sortBy: (item) => {
            return item.applications_count ? parseInt(item.applications_count) : null;
          },
          sortExportParam: 'applications_count',
        },
        'top_competitors': {
          header: 'Comp. in Top 10',
          style: {},
          headerStyle: {minWidth: '120px', textAlign: 'right'},
          orientation: 'right',
          sortBy: (item) => {
            return item.top_competitors.competitors_count ?? 0;
          },
          sortExportParam: 'competitors_count',
        },
      };
    },
    filtersConfigs() {
      return [
        popularityFilter(this, 'sap'),
        rankFilter(this),
        estDailyImprFilter(this, 'daily_impression'),
        difficultyFilter(this),
        compInTop10Filter(this),
        nonCompInTop10Filter(this),
        wordsCountFilter(this),
        rankTrendFilter(this),
        languageFilter(this),
      ];
    },
    checkedItems,
    allTranslated,
    groupsConfig,
    datePickerConfig() {
      return {
        dateFilter: this.dates,
        minDate: getDefaultMinDate(),
        maxDate: getNDaysAgoDate(1),
        resetDatePicker: () => {
          this.fetchData();
        },
        applyDatePicker: (v) => {
          this.$emit('date-filter-changed', v);
          // this.dates = v;
          // this.fetchData();
        },
      };
    },
    isShowTableBlur
  },
  mounted() {
    this.dates = this.dateFilter;
    this.fetchData();
  },
  methods: {
    capitalizeFirstLetter,
    arraySum,
    formatDate,
    async addKeywordToTracking(item) {
      if (item.keyword.is_tracked) {
        return;

        item.keyword.is_tracked = false;
        await keywordsTracking.deleteKeywords(
            this.currentApp.id,
            [item.keyword.tracked_id],
            this.currentCountry.code,
        );
        item.keyword.tracked_id = null;
      } else {
        item.keyword.is_tracked = true;

        let result = await keywordsTracking.addKeywords(
            this.currentApp.id,
            this.currentCountry.code,
            [item.keyword.term]
        );

        item.keyword.tracked_id = result[0].saved_keywords_ids[item.keyword.term];
      }

      this.$emit('tracking-keywords-changed');
    },
    async addKeywordsToTracking() {
      let items = this.checkedItems.map((item) => {
        return item.keyword.term;
      });

      let indexByKeywordName = {};
      this.checkedItems.forEach((item) => {
        item.keyword.is_tracked = true;
        indexByKeywordName[item.keyword.term] = item;
      });

      let result = await keywordsTracking.addKeywords(
          this.currentApp.id,
          this.currentCountry.code,
          items
      );

      result.forEach((response) => {
        Object.entries(response.saved_keywords_ids).forEach(([index, id]) => {
          indexByKeywordName[index].keyword.tracked_id = id;
        });
      });

      this.$emit('tracking-keywords-changed');
    },
    copyKeywords() {
      copyKeywords(this);
    },
    async translateAll() {
      await translateAll(this);
    },
    async fetchData() {
      this.loading = true;

      this.selectedGroup = this.groupsConfig.list[3];
      this.selectedTab = null;

      this.items = [];

      let urlQuery = '?app_id=' + this.workingApp.id
          + '&country_code=' + this.currentCountry.code;

      urlQuery += '&active_app_id=' + this.currentApp.id;

      urlQuery += '&date_from=' + formatDate(this.dates.start, 'digits-dash');
      urlQuery += '&date_to=' + formatDate(this.dates.end, 'digits-dash');

      urlQuery += '&mode=two_days';

      const data = await httpRequest('GET', process.env.VUE_APP_API_URL + this.$ApiUrls.rankingKeywords.RANKING_KEYWORDS + urlQuery);

      this.items = data.list;
      this.groupsInfo = data.groups_counts;
      this.total = data.total;
      this.selectedGroup = this.groupsConfig.list[3];
      this.groupsSelectReloadKey += 1;

      await nextTick(() => {
        this.$refs.table.resetPagination();
      });

      this.loading = false;
    },

    liveSearchTriggerHandler(e, flow = 'live') {
      openGlobalModal(LiveSearchGlobalModalAdapter, {
        'country': this.currentCountry,
        'flow': flow,
        'keyword': e.keyword.term
      })
    },
    sapChartTriggerHandler(e) {
      openGlobalModal(SapChartGlobalModalAdapter, {
        'country': this.currentCountry,
        'keyword': e.keyword.term
      })
    },
    autoSuggestTriggerHandler(e) {
      openGlobalModal(AutosuggestsGlobalModalAdapter, {
        'country': this.currentCountry,
        'keyword': e.keyword.term,
        'app': this.currentApp
      })
    },
  }
}
</script>

<template>
  <div v-show="loading">
    <progress-indicator>Loading Keywords...</progress-indicator>
  </div>
  <div class="common-keywords-table metadata-keywords-table" v-show="!loading">
    <simple-info-table
        ref="table"
        :download-url="downloadUrl"
        :search-by="[(v) => v.keyword.term]"
        :items="items"
        :columns="columns"
        :filter-configs="filtersConfigs"
        :fake-pagination-total="isShowTableBlur ? total : null"
        :date-picker-config="datePickerConfig"
        default-sort-index="popularity"
        :default-sort-direction="'desc'"
        :multiselect="true"
        :sticky-header="true"
        :sticky-columns="[-1, 'keywords']"
        :table-wrap-styles="{maxHeight: 'calc(100vh - ' + tableMaxHeightFix + 'px)', overflowY: 'scroll'}"
        :table-wrap-classes="['common-scrollbar', 'scrollWidth-10', 'color-600', 'border-radius-8', 'background-color-400']"
        :rows-per-page-list="[50, 100]"
        @search-input-changed="(v) => {searchInputValue = v}"
    >
      <template v-slot:headers="slotProps">
        <div v-if="'first_rank' === slotProps.index">
          {{ slotProps.header }}
          <br>
          <span class="extended-date">{{ formatDate(this.dates.start, 'month-day') }}</span>
        </div>
        <div v-else-if="'last_rank' === slotProps.index">
          {{ slotProps.header }}
          <br>
          <span class="extended-date">{{ formatDate(this.dates.end, 'month-day') }}</span>
        </div>
        <div v-else-if="'keywords' === slotProps.index && checkedItems.length">
          {{ checkedItems.length }}/<span style="font-weight: 400">{{
            $refs.table.provider.getPagination().total
          }}</span>
        </div>
        <div v-else>
          {{ slotProps.header }}
        </div>
      </template>
      <template v-slot:headersAfter="slotProps">
        <div class="display-flex" v-if="slotProps.index === 'keywords'">
          <template v-if="checkedItems.length">
            <div class="font-20 ml-15 cursor-pointer hover-color-primary color-700" @click="copyKeywords"
                 v-tooltip="{ text: 'Copy', position: 'bottom', onClickText: 'Copied' }">
              <svg-icon icon="copy"></svg-icon>
            </div>
            <div class="font-20 ml-15 cursor-pointer hover-color-primary color-700" @click="addKeywordsToTracking"
                 v-tooltip="{ text: 'Add to Tracking Keywords', position: 'bottom', onClickText: 'Added' }">
              <svg-icon icon="plus"></svg-icon>
            </div>
          </template>
          <template v-else>
            <div>
              <translate-tool :active="allTranslated" @translate-clicked="translateAll"/>
            </div>
          </template>
        </div>
      </template>
      <template v-slot:endOfFiltersBlock>
        <div class="ml-15" v-if="groupsConfig.list.length > 0">
          <custom-select-new
              :key="groupsSelectReloadKey"
              :options="groupsConfig.list"
              :pre-selected="groupsConfig.list[3]"
              value-field="id"
              label-field="name"
              icon-field="icon"
              :styles="{
                  label: {
                    width: '220px'
                  }
                }"
              @changed="groupsConfig.onChange"
          ></custom-select-new>
        </div>
      </template>
      <template v-slot:beforeTableBlock>
        <Tabs :groups-info="groupsInfo" :selected-group="selectedGroup" :items="items"
              @select-tab="(index) => selectedTab = index"></Tabs>
      </template>

      <template v-slot:items-keywords="slotProps">
        <keyword-tool-new
            :key="slotProps.item.keyword.term"
            :is-tracked="slotProps.item.keyword.is_tracked"

            :value="slotProps.item.keyword.term"
            :translated-value="slotProps.item.translate"
            :search-input-value="searchInputValue"

            :show-by-trigger-styles="{
              'right': '35px',
            }"
        >
          <template v-slot:endOfNameSection>
            <div class="display-flex">
              <div class="font-20 ml-15 cursor-pointer color-700 hover-color-green"
                   :class="{'color-green': slotProps.item.keyword.is_tracked}"
                   @click="addKeywordToTracking(slotProps.item)"
                   v-tooltip="{ text: 'Add&nbsp;to&nbsp;Tracking&nbsp;Keywords', position: 'right' }">
                <svg-icon :icon="slotProps.item.keyword.is_tracked ? 'check-alt' : 'plus'"></svg-icon>
              </div>
            </div>
          </template>
          <template v-slot:hoverSection>
            <div class="display-flex actions-block">
              <div class="mr-12">
                <translate-one-tool :set-result-to="slotProps.item"
                                    :to-translate-string="slotProps.item.keyword.term"></translate-one-tool>
              </div>
              <div class="tool-item" v-tooltip="{ text: 'Live search', position: 'left', classes: ['no-wrap-text'] }">
                <svg-icon icon="livesearch-icon" class="live-search-icon cursor-pointer hover-color-secondary-yellow"
                          @click="liveSearchTriggerHandler(slotProps.item)"/>
              </div>
              <div class="tool-item" v-tooltip="{ text: 'Autosuggest', position: 'left' }">
                <svg-icon icon="autosuggest-icon" class="autosuggest-icon cursor-pointer"
                          @click="autoSuggestTriggerHandler(slotProps.item)"/>
              </div>
            </div>
          </template>
        </keyword-tool-new>
      </template>
      <template v-slot:items-popularity="slotProps">
        <div class="display-flex">
          <sap-tool :keyword="slotProps.item.keyword.term"
                    :sap="slotProps.item.sap"
                    :tooltipPos="slotProps.isLast ? 'top' : 'bottom'"
                    :store-key="currentApp.store.key"
                    @sap-modal-trigger-clicked="sapChartTriggerHandler(slotProps.item)"/>
        </div>
      </template>
      <template v-slot:items-impression="slotProps">
        <div>
          <impressions-tool :value="slotProps.item.daily_impression"/>
        </div>
      </template>
      <template v-slot:items-difficulty="slotProps">
        <div class="display-flex">
          <difficulty-tool :value="slotProps.item.difficulty"/>
        </div>
      </template>
      <template v-slot:items-first_rank="slotProps">
        <div>
          <app-rank-tool :item="slotProps.item.first_rank_info"/>
        </div>
      </template>
      <template v-slot:items-last_rank="slotProps">
        <div>
          <app-rank-tool :item="slotProps.item.last_rank_info"/>
        </div>
      </template>
      <template v-slot:items-applications_count="slotProps">
        <div>
          <results-tool :value="slotProps.item.applications_count"/>
        </div>
      </template>
      <template v-slot:items-top_competitors="slotProps">
        <div>
          <competitors-in-top-tool
              :key="`${currentApp.id}|${currentCountry.code}|${slotProps.item.keyword.term}|comp_top_tool|${slotProps.item.top_competitors.competitors_count}|${slotProps.item.top_competitors.non_competitors_count}`"
              :total="slotProps.item.top_competitors.found_top_count ?? 0"
              :competitors="slotProps.item.top_competitors.competitors_count ?? 0"
              :non-competitots="slotProps.item.top_competitors.non_competitors_count ?? 0"
              :tooltipPos="slotProps.isLast ? 'top' : 'bottom'"
              :indirect-competitors="slotProps.item.top_competitors.indirect_competitors_count ?? 0"
              @clicked="liveSearchTriggerHandler(slotProps.item, 'db')"
          />
        </div>
      </template>
      <template v-slot:containerEnd>
        <UnlockFullTableBlur v-if="isShowTableBlur"></UnlockFullTableBlur>
      </template>
    </simple-info-table>
  </div>
</template>

<style scoped lang="scss">
.tool-item {
  display: block !important;
}

.extended-date {
  white-space: nowrap;
  color: var(--neutral-700);
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}
</style>